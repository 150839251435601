<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="user_name">
        <el-input v-model="ruleForm.user_name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="bank_num">
        <el-input v-model="ruleForm.bank_num" placeholder="请输入银行卡号"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="cert_num">
        <el-input v-model="ruleForm.cert_num" placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="sms_code">
        <el-input v-model="ruleForm.sms_code" placeholder="请输入验证码" style="width: 350px"></el-input>
        <div class="code" v-if='codeCd'>获取验证码({{ long }})</div>
        <div class="code" v-else @click="sendPhoneCode('ruleForm')">获取验证码</div>
      </el-form-item>
      <el-form-item prop="type">
        <!-- <el-checkbox-group v-model="ruleForm.type">
          <el-checkbox name="type" style="color:#004CA7">
            <span @click.stop="">同意《快捷支付协议》</span>
          </el-checkbox>
        </el-checkbox-group> -->
        <el-checkbox v-model="ruleForm.type"></el-checkbox>
        <span style="padding-left: 10px;color:#004CA7">同意<span style="color:#004CA7;text-decoration: none;cursor: pointer;" @click="formVisible=true">《快捷支付协议》</span></span>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 200px" type="primary" @click="submitForm('ruleForm')">添加</el-button>
        <el-button style="width: 200px" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 表单弹窗 -->
    <el-dialog
      title="快捷支付协议"
      append-to-body
      :visible.sync="formVisible"
      width="800px"
      :before-close="closeDialog"
    >
      <Agreement
        ref="formModal"
        v-if="formVisible"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { bankCardAdd, bankSmsCode } from '@/api'
import Agreement from "./agreement";
export default {
  components:{
    Agreement
  },
  data() {
    const code = (rule, value, callback) => {
      if(this.phoneCode == value){
        callback()
      }else {
        callback(new Error('验证码不正确'))
      }
    }
    return {
      formVisible: false,
      ruleForm: {
        user_name: "",
        phone: "",
        bank_num: "",
        cert_num: "",
        sms_code: "",
        type: "",
        apply_id: "", // 短信返回的id
      },
      codeCd: false,
      long: 60,
      phoneCode: '',
      rules: {
        user_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        bank_num: [{ required: true, message: "请输入银行卡号", trigger: "blur" }],
        cert_num: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
        sms_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '验证码格式不正确', trigger: 'blur' },
          // { validator: code, trigger: 'blur'}
        ],
        type: [{ required: true, message: "请先阅读同意《快捷支付协议》", trigger: "change" }]
      },
    };
  },
  methods: {
    // 关闭弹框
    closeDialog() {
      this.formVisible = false;
    },
    // 发送验证码
    async sendPhoneCode(formName){
      var user_name = false 
      var phone = false 
      var bank_num = false 
      var cert_num = false
      
      await this.$refs[formName].validateField('user_name', async (valid) => {
        if (!valid) {
          user_name = true
        }else{
          return false
        }
      });
      await this.$refs[formName].validateField('phone', async (valid) => {
        if (!valid) {
          phone = true
        }else{
          return false
        }
      });
      await this.$refs[formName].validateField('bank_num', async (valid) => {
        if (!valid) {
          bank_num = true
        }else{
          return false
        }
      });
      await this.$refs[formName].validateField('cert_num', async (valid) => {
        if (!valid) {
          cert_num = true
        }else{
          return false
        }
      });
      if (user_name && phone && bank_num && cert_num) {
        // 所有字段验证通过
        this.getUsersSendCode()
        // 开启计时
        this.codeCd = true
        const timer = setInterval(() => {
          this.long--
          if (this.long <= 0) {
            this.long = 60
            this.codeCd = false
            clearInterval(timer)
          }
        }, 1000)
      } else {
        // 至少一个字段验证失败
        return false
      }
    },
    // 获取验证码
    getUsersSendCode(){
      bankSmsCode({
        u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
        user_name: this.ruleForm.user_name,
        phone: this.ruleForm.phone,
        bank_num: this.ruleForm.bank_num,
        cert_num: this.ruleForm.cert_num,
      }).then(res => {
        if(res.code == 1){
          // this.phoneCode = res.data   // 没返回短信验证码
          this.ruleForm.apply_id = res.data.id
          this.$message.success(res.msg)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm
          delete form.type
          bankCardAdd({
            u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
            ...form
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.$emit('ok')
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
  .code{
    font-size: 14px;
    cursor: pointer;
    color: #004ca7;
    margin-left: 10px;
    flex: 1;
    text-align: right;
    width: 100px;
    float: right;
  }
  /deep/.el-dialog__body{
    padding-top: 0 !important;
  }
</style>
