<template>
  <div class="">
    <el-descriptions :column='1' label-class-name="my-label" :colon='false'>
      <el-descriptions-item label="订单号">{{ recordInfo.reservation_id }}</el-descriptions-item>
      <el-descriptions-item label="运费">{{ recordInfo.freight }}</el-descriptions-item>
      <el-descriptions-item label="代收货款">{{ recordInfo.money }}</el-descriptions-item>
      <el-descriptions-item label="支付单号">{{ recordInfo.pay_id }}</el-descriptions-item>
    </el-descriptions>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="70px"
      class="demo-ruleForm"
    >
      <el-form-item label="验证码" prop="sms_code">
        <el-input v-model="ruleForm.sms_code" placeholder="请输入验证码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">支付</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { unionpay } from '@/api'
  export default {
    props: {
      recordInfo: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        ruleForm: {
          sms_code:''
        },
        rules: {
          sms_code: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            { pattern: /^\d{6}$/, message: '验证码格式不正确', trigger: 'blur' }
          ],
        }
      };
    },
    methods: {
      submitForm(ruleForm) {
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            unionpay({
              order_id: this.recordInfo.reservation_id,
              pay_id: this.recordInfo.pay_id,
              sms_code: this.ruleForm.sms_code,
            }).then(res => {
              if(res.code == 1){
                this.$message.success(res.msg)
                // this.$router.push({ path: '/order/list'})
                location.reload()
              }else{
                this.$message.error(res.msg)
              }
            })
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.ruleForm = {}
      }
    }
  }
</script>
<style lang='scss' scoped>
/deep/ .el-descriptions-item__label{
  min-width: 60px;
  flex-shrink: 0;
  justify-content: flex-end;
  font-size: 15px;
  text-align: right !important;
}
</style>