<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="realname">
        <el-input v-model="ruleForm.realname" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="省市县" prop="province">
        <el-cascader
          v-model="value"
          placeholder="请选择省市县"
          :options="dataCity"
          :props="{ expandTrigger: 'hover', value: 'label'}"
          style="width: 100%"
          @change="handleChange"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item label="地址类型" prop="address_type">
        <el-select v-model="ruleForm.address_type" placeholder="请选择地址类型" style="width: 100%">
          <el-option label="寄件人地址" :value="1"></el-option>
          <el-option label="收件人地址" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="is_default">
        <el-checkbox v-model="ruleForm.is_default" :checked="ruleForm.is_default == 1 ? true : false" :true-label="1" :false-label="0" name="is_default">设置成默认地址</el-checkbox>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button size='small' type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size='small' @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addressAdd, addressUpdate } from '@/api'
const  dataCity = require('@/common/city.json')
import { validatenull } from "@/common/common.js"
export default {
  props:{
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataCity,
      value: [],
      ruleForm: {
        realname: "",
        mobile: "",
        address: "",
        province: "",
        city: "",
        area: "",
        address_type: "",
        is_default: 0
      },
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          //{ pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        province: [{ required: true, message: "请选择省市县", trigger: "change" }],
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        address_type: [{ required: true, message: "请选择地址类型", trigger: "change" }]
      },
      id:''
    };
  },
  mounted(){
    if(!validatenull(this.record)){
      this.id = this.record.id
      this.value = [this.record.province,this.record.city,this.record.area]
      this.ruleForm = this.record
    }
  },
  methods: {
    //获取省市县
    handleChange(value) {
      this.ruleForm.province = value[0]
      this.ruleForm.city = value[1]
      this.ruleForm.area = value[2]
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm
          if(this.id){
            addressUpdate({
              address_id:this.id,
              u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
              ...form
            }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.$emit('ok')
            }else{
              this.$message.error(res.msg)
            }
          })
          }else{
            addressAdd({
              u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
              ...form
            }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.$emit('ok')
            }else{
              this.$message.error(res.msg)
            }
          })
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.value = []
    },
  },
};
</script>
<style lang="scss" scoped>
  .code{
    font-size: 14px;
    cursor: pointer;
    color: #004ca7;
    margin-left: 10px;
    flex: 1;
    text-align: right;
    width: 100px;
    float: right;
  }
</style>
