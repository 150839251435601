<template>
  <div class="">
    <el-card class="box-card">
      <el-tabs v-model="activeName">
        <el-tab-pane label="运单号" name="first">
          <div class="search-form">
            <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="100px" label-position="left">
              <el-form-item prop="num">
                <el-input
                  clearable
                  size="small"
                  v-model="searchForm.num"
                  placeholder="请输入运单号"
                  style="width: 400px"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
                <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- 时间线 -->
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
              :timestamp="activity.time">
              {{activity.desc}}
            </el-timeline-item>
          </el-timeline>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { orderTrack } from '@/api'
export default {
  data () {
    return {
      activeName: 'first',
      searchForm: {
        num:''
      },
      activities: []
    }
  },
  created () {
    const num = this.$route.query.num
    this.searchForm.num = num
    if(num){
      this.getOrderTrack()
    }
  },
  methods: {
    // 获取订单轨迹
    getOrderTrack(){
      orderTrack({no: this.searchForm.num}).then(res => {
        if(res.code == 1){
          this.activities = []
          if(res.data.length > 0){
            this.activities = res.data.map((item,index) => {
              return {
                ...item,
                size: index+1 == res.data.length ? 'large' : 'normal',
                icon: index+1 == res.data.length ? 'el-icon-truck' : '',
                color: index+1 == res.data.length ? '#0bbd87' : ''
              }
            })
          }
          
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.activities = [];
    },
    // 去筛选
    handleSearch() {
      this.activities = [];
      this.getOrderTrack();
    }, 
  }
}
</script>
<style lang="scss" scoped>
  .box-card{
    width: 80%;
    margin: 20px auto;
    min-width: 1200px;
  }
  /deep/.el-timeline-item__wrapper{
    text-align: left;
  }
</style>