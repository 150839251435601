<template>
  <div class="">
    <img
      :src="require('@/assets/news/newsbanner.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%"
    />
    <!-- 面包屑 -->
    <div class="x-help-bread">
      首页 <i class="el-icon-arrow-right x-help-icon"></i> 
      新闻资讯
    </div>
    <!-- 新闻 -->
    <div class="news">
      <div class="news-item" @click="goToNewsDetail(0)">
        <div class="news-item-l">
          <div class="news-item-l-title">供应链一体化趋势下，合同物流的变革</div>
          <div class="news-item-l-content">核心导读：

1)合同物流的本质是做资源配置管理。如何替代货主客户在货主与底层运力资源池之间寻找最优解，以最低的成本，提供高性价比的服务，实现管理效率的最优，才是合同物流最大的价值;

2)供应链可通过由上到下、由后向前、横向协同等多种方式实现一体化建设;

3)一体化供应链背景下，合同物流行业整合、多细分行业横向协同的规模化趋势不可逆。

1、合同物流的存在价值

合同物流的本质是做资源配置管理。以最低的成本，提供高性价比的服务，实现管理效率的最优，才是合同物流最大的价值。

1.1 合同物流的产生与存在

从独立的第三方物流诞生的基本经济原理来看，生产流通企业用于物流方面的管理成本(含人力、寻源、管理等)大于了可以实现同等职能的物流采购成本时，作为理性的经济人，一定会选择成本更低的外部物流采购。这就为独立的物流企业的产生提供了理论基础。

生产流通企业专注主业，削减物流部门的具体职能，开始打包采购物流解决方案，这也就为合同物流企业的产生并发展壮大提供了机会。总得来看，合同物流是因专业化分工而产生的一个细分行业。

当然，并不是所有的合同物流企业都是因替代生产流通企业的物流部门而产生的独立第三方企业。其中有一部分，则是生产流通企业的物流部门，凭借生产流通企业丰厚的资源发展壮大演化而来的，成为了专业的物流解决方案企业，并在主要服务母公司业务的同时，开放社会化业务。现实中，这部分企业反而成了合同物流的典型企业代表。</div>
        </div>
        <div class="news-item-r">
          <img :src="require('@/assets/news/news1.png')" alt="">
        </div>
      </div>
      <div class="news-item" @click="goToNewsDetail(1)">
        <div class="news-item-l">
          <div class="news-item-l-title">物流的数据分析主要分析什么？</div>
          <div class="news-item-l-content">在做物流规划设计时，人们往往对设计指标感到茫然，对新员工尤其如此。有些设计人员比较急躁，一上来就急于做方案、画图，结果画来画去，就不知道自己到底要做什么了。耽误了不少时间不说，设计方案要么不知所云，要么离题万里，对用户是一个很大的伤害。

一个良好的设计习惯，往往是应该首先明确设计目标，了解清楚设计要求之后，再去动笔，就比如写文章，总应该先确定写什么主题，目的是什么，给谁看，然后才开始写提纲、反复推敲提纲、找好素材和参考资料，再动笔写，然后再反复推敲、修改润色。不然，就很难写成一篇有质量的文章。画图虽然很重要，但到底只是一种比较简单的劳动，而画什么，表现什么主题，达到什么目标才是设计的关键。

设计是如此，对一个设计方案的评价也是如此。我遇到很多客户要求评价一个总体的方案，其实这是很难的。其中关键的一点就是，方案是设计需求的响应，对方案进行评估，首先要对设计目标进行分析和评估，这才是根本。

数据分析是一件很严肃和需要专业知识的工作，并非仅仅对数据进行简单的加减排列组合就可以了。我特别反对那些不注重数据分析的客户。一项设计，设计指标是基础。基础出现问题，你选用的设备再好，系统再先进，也是于事无补的。

其实物流仓储系统的规划设计也没有那么难，关键一点是需求要清楚明确。而需求是可以用数据来描述和定义的。一个项目，其关键数据也就那么几个而已，如收货量、发货量、库存量、拆零量、SKU等，并不难掌握。本文就讲一讲这些最基本的需求，为了便于理解，主要从物流仓储的几个环节进行描述。

基础数据

在进行系统性描述之前，一定要清楚物流的作业当量最后是以小时来计算的(当然还可以细化到半小时，甚至更小单位)。所以，我们所有的物流量，最终要以小时当量来计算。然而，从用户那里得到的实际的设计指标，很可能是年度的作业纲领，如年配送100亿。这个数据非常重要，却也是非常不确定的，因为从这个指标推导下来，就会看到，每年的作业天数、每天的作业时间、货物的价值、仓库库存周转次数等，对最终设计都有很大的影响。所以，这些关联数据应该是要首先明确的。</div>
        </div>
        <div class="news-item-r">
          <img :src="require('@/assets/news/news2.png')" alt="">
        </div>
      </div>
      <div class="news-item" @click="goToNewsDetail(2)">
        <div class="news-item-l">
          <div class="news-item-l-title">家电行业智能制造园区物流系统的规划与设计</div>
          <div class="news-item-l-content">为实现智能制造，家电生产企业在园区规划时要结合其生产特性，利用智能物流理念连通各个生产环节，实现满足家电制造工艺流程要求的JIT配送。本文基于家电制造业的生产流程，提出智能制造园区规划方案，重点结合行业实际情况提供了各类库区采用的物流手段，介绍了园区物流系统的设备监控、信息管理措施，并对系统规划的关键点进行了阐述。

在《中国制造2025》战略引领下，我国家电制造企业加速向数字化、信息化、智能化转型，生产模式体现为大规模定制化生产。例如，过去消费者可选购的品类很少，而现在不仅各大品牌型号多样化，更实质性的进步是消费者可对零部件种类做出更多选择，尤其是当零部件数量增长后，各种配置呈指数级增长。此外，随着我国商业模式持续升级，消费者购买行为和消费习惯发生转变，全渠道销售、新零售等趋势明显，促使家电行业供应链与物流集中呈现出小批量、多频次及快速响应的特点。

我国家电制造行业经过多年发展， 在生产设备自动化方面已经积淀了一定基础，而物流环节普遍是短板，加上大规模定制生产、商业模式持续升级以及控制成本等因素，迫切需要物流系统发挥生产协同作用。因此，近年越来越多的制造企业开始或准备筹建智能物流建设项目，除在传统的成品仓储环节实现自动化物流外，在生产、制造环节中也在引进越来越多的自动化、智能化物流技术和设备。比如，以各种类型的自动化仓库、工业机器人、AGV、空中运输系统、自动分拣系统等代替传统人工作业，以及应用大数据、云计算、数据采集与监视控制系统、数据挖掘等新一代信息技术和供应链管理方法，提高车间生产效率，降低生产物流成本。

家电行业物流是一个逻辑严密的系统工程，边界起于原材料、外购件的投入，终止于成品出库，连通供应商、生产计划、工位物流、销售、配送、维护等众多环节，贯穿生产制造全过程，横跨企业各职能部门。并且，对象物料随生产进程不断改变实物状态，物料配送和搬运有明确的节拍，还包含及执行过程中的差异管理，还有过程中的故障告警及应急方案等;为此，家电企业在规划生产园区时，要结合其特有的产品需求及工艺环节之间的逻辑关系，用智能物流理念再造流程、定义建筑功能、优化物流路径，在预定的时间通过合理的方式，将适当数量和包装的物料送到指定地点，实现满足家电制造工艺流程要求的JIT配送。</div>
        </div>
        <div class="news-item-r">
          <img :src="require('@/assets/news/news3.png')" alt="">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goToNewsDetail(num){
      this.$router.push({ path: '/newsDetail', query: { num } });
    },
  },
};
</script>
<style lang="scss" scoped>
  .x-help-bread {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: #004ca7;
    box-sizing: border-box;
    padding-left: 10%;
    color: #fff;
    font-size: 14px;
    .x-help-icon {
      padding: 0 5px;
      margin-top: 3px;
    }
  }
  .news{
    width: 80%;
    min-width: 1200px;
    margin: 40px auto;
    .news-item{
      width: 100%;
      height: 160px;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .news-item-l{
        flex: 1;
        box-sizing: border-box;
        padding: 10px 20px;
        .news-item-l-title{
          font-size: 18px;
          text-align: left;
          line-height: 32px;
          color: #004ca7;
          margin: 10px 0;
        }
        .news-item-l-content{
          font-size: 14px;
          text-align: left;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .news-item-r{
        width: 200px;
        height: 100%;
        margin-left: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 150px;
          height: 82px;
          border-radius: 8px;
        }
      }
    }
  }
</style>
