<template>
  <div>
    <div class="backs">
      <div class="backs-item" v-for="(item,index) in list" :key="index" @click="Pay(item)">{{ item.name }}</div>
    </div>
    <el-dialog
      title="支付信息"
      append-to-body
      :visible.sync="formVisible"
      width="600px"
      :before-close="closeDialog"
    >
      <PayDetail
        ref="formModal"
        :recordInfo='recordInfo'
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { bankIndex, eBankList, eBankPay, unionpaySms } from '@/api'
import PayDetail from "./payDetail";
export default {
  components: {
    PayDetail,
  },
  props:{
    type:{
      type: Number,
      default: 1
    },
    records: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formVisible: false,
      recordInfo: {},
      list:[]
    }
  },
  mounted(){
    this.getList()
  },
  methods: {
    getList(){
      if(this.type == 1){
        bankIndex({
          u_id:JSON.parse(window.localStorage.getItem("userInfo")).id,
          page: 1,
          limit: 1000
        }).then(res => {
          if(res.code == 1){
            this.list = res.data.data.map(item => {
              return{
                ...item,
                name:item.bank_name
              }
            })
          }
        })
      }else if(this.type == 2){
        eBankList().then(res => {
          if(res.code == 1){
            this.list = res.data
          }
        })
      }
    },
    // 跳转支付
    Pay(item){
      if(this.type == 1){
        unionpaySms({
          u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
          bank_id: item.id,
          order_id:this.records.id
        }).then(res => {
          if(res.code == 1){
            this.formVisible = true
            this.recordInfo = {
              ...this.records,
              pay_id: res.data.id
            }
            this.$message.success(res.msg)
          }else{
            this.$message.error(res.msg)
          }
        })
      }else if(this.type == 2){
        eBankPay({
          order_id: this.records.id,
          bank_num_code: item.code,
          is_platform: 'pc'
        }).then(res => {
          if(res.code == 1){
            window.open(res.data.data, '_blank');
          }
        })
      }
    },
    // 关闭弹框
    closeDialog() {
      this.recordInfo = {};
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false;
      this.recordInfo = {}
      // this.getOrderIndex();  //刷新列表
    },
  }
}
</script>
<style lang="scss" scoped>
  .backs{
    min-height: 300px;
    max-height: 400px;
    overflow-y: scroll;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
    .backs-item{
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 20px;
      border-radius: 8px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .backs-item:hover{
      border-color: #409EFF;
      color: #409EFF;
    }
  }
  /deep/.el-dialog__body{
    padding: 10px 20px 30px;
  }
</style>