<template>
  <div class="x-ly-top">
    <div class="top-bar__left">
      <div class="x-ly-breadcrumb">
        <i class="el-icon-s-unfold" v-if='isCollapse' @click="changeIsCollapse(false)"></i>
        <i class="el-icon-s-fold" v-else @click="changeIsCollapse(true)"></i>
      </div>
    </div>
    <div class="top-bar__title">
    </div>
    <div class="top-bar__right">
      <img class="top-bar__img"
           src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" @click='gotoUser'>
      <el-dropdown>
        <span class="el-dropdown-link" @click="gotoUser">
          {{ userInfo.nickname }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleLogOut">
            退出登录
          </el-dropdown-item>
          <!-- <el-dropdown-item @click.native="logout"
                            divided>退出登录</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "top",
  props: ['triggerMethod'],
  data () {
    return {
      isCollapse: false,//是否折叠左侧菜单栏  false不折叠
      userInfo: {}
    };
  },
  created () { 
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
  },
  methods: {
    changeIsCollapse(e){
      this.isCollapse = e
      this.triggerMethod(e)
    },
    handleLogOut(){
      window.localStorage.removeItem("userInfo");
      this.$router.push('/login')
    },
    gotoUser(){
      this.$router.push('/info')
    }
  }
};
</script>

<style lang="scss" scoped>
.x-ly-top {
    position: relative;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 28px;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    white-space: nowrap;
    background: #004CA7;
}
.x-ly-breadcrumb {
    height: 100%;
    i{
        font-size: 28px !important;
        cursor: pointer;
    }
    &--active {
        transform:rotate(90deg);
    }
}
.top-bar__img {
    margin: 0 5px;
    padding: 2px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    box-sizing: border-box;
    border: 1px solid #eee;
    vertical-align: middle;
    cursor: pointer;
}

.top-bar__left,
.top-bar__right {
  height: 50px;
  position: absolute;
  margin-top: 2px;
  top: 0;
  color: #fff;
  i{
      line-height: 50px;
  }
  .el-dropdown-link{
    color: #fff;
  }
}

.top-bar__left {
    left: 10px;
}

.top-bar__right {
    right: 10px;
    display: flex;
    align-items: center;
}
.top-bar__title {
    height: 100%;
    padding-left: 30px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: inherit;
    font-weight: 400;
}
</style>