<template>
  <div class="info">
    <div class="info-title">个人信息</div>
    <div class="info-user">
      <img class="info-user-img"
           src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png">
      <div class="info-user-box">
        <p>登录用户：{{ ruleForm.phone }}</p>
        <!-- <p>登录时间：2020-12-12 00:00:00</p> -->
      </div>
    </div>
    <div class="info-title">基本信息</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="usernames">
        <el-input v-model="ruleForm.usernames" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="ruleForm.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio :label='1'>男</el-radio>
          <el-radio :label='2'>女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item label="省市县" prop="region">
        <el-cascader
          v-model="value"
          :options="dataCity"
          :props="{ expandTrigger: 'hover', value: 'label'}"
          style="width: 100%"
          @change="handleChange"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item label="邮编" prop="zip_code">
        <el-input v-model="ruleForm.zip_code" placeholder="请输入邮编"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { usersInfo, usersUpdate } from '@/api'
const  dataCity = require('@/common/city.json')
export default {
  data() {
    return {
      value: [],
      dataCity,
      ruleForm: {
        usernames: "",
        nickname: "",
        email: "",
        phone: "",
        type: [],
        sex: 1,
        zip_code: "",
        province: "",
        city: "",
        district: "",
        address: ""
      },
      rules: {
        // usernames: [
        //   { required: true, message: '请输入姓名', trigger: 'blur' }
        // ],
        // email: [
        //   { required: true, message: '请输入邮箱', trigger: 'blur' }
        // ],
        //         email: [
        //   { required: true, message: '请输入邮箱', trigger: 'blur' }
        // ],
        //         email: [
        //   { required: true, message: '请输入邮箱', trigger: 'blur' }
        // ],
      },
    };
  },
  created(){
    this.getUsersInfo()
  },
  methods: {
    handleChange(value) {
      this.ruleForm.province = value[0]
      this.ruleForm.city = value[1]
      this.ruleForm.district = value[2]
    },
    // 获取用户信息
    getUsersInfo(){
      usersInfo({u_id: JSON.parse(window.localStorage.getItem("userInfo")).id}).then(res => {
        if(res.code == 1){
          this.ruleForm = res.data
          this.value = [res.data.province,res.data.city,res.data.district]
        }
      })
    },
    // 编辑用户信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          usersUpdate(this.ruleForm).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.getUsersInfo()
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
  .info{
    background: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    .demo-ruleForm{
      width: 50%;
    }
  }
  /deep/.el-form-item__content{
    text-align: left;
  }
  .info-title{
    width: 100%;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    font-weight: bold;
  }
  .info-user{
    height: 60px;
    width: 100%;
    border-bottom: 3px solid #ccc;
    display: flex;
    align-items: center;
    .info-user-img{
      margin: 0 20px 0 10px;
      padding: 2px;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      box-sizing: border-box;
      border: 1px solid #eee;
      vertical-align: middle;
    }
    .info-user-box{
      flex: 1;
      p{
        font-size: 14px;
        line-height: 28px;
        text-align: left;
        margin: 0;
        padding: 0;
      }
    }
  }
</style>