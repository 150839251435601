<template>
  <div class="">
    <!-- 轮播图 -->
    <el-carousel indicator-position="outside" style="height: 600px">
      <el-carousel-item v-for="(item,index) in banner" :key="index" style="height: 600px">
        <img :src="item.url" alt="Carousel Image">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容 -->
    <div class="x-home-content">
      <div class="x-home-tip">
        <!-- <div class="x-home-trace" :class="isShowAll==1 ? '' : (isShowAll==3 ? 'x-home-dh' : 'x-home-hd')">
          <p>货物追踪</p>
          <div class="home-trace">
            <el-input type="textarea" v-model="form.desc" @blur='handleBlur' @focus='handleFocus'></el-input>
            <el-button size='small' style="position: absolute;right: 5px;bottom: 5px;" @click="search" :style="{ opacity: isShowAll==3 ? 1 : 0 }" >查询</el-button>
          </div>
        </div> -->
        <div class="x-home-place x-trace">
          <el-input v-model="form.desc" placeholder="请输入运单号"></el-input> 
          <el-button style="margin-left: 20px" @click="search">查询</el-button>
        </div>
        <div class="x-home-place">
          <el-image
            style="width: 32px; height: 32px"
            :src="require('@/assets/index/place.png')"
            fit="contain"
          ></el-image>
          <span>在线下单</span>
        </div>
      </div>
      <!-- 主营 -->
      <div class="x-common">
        <p class="x-common-title">主营业务</p>
        <el-tabs v-model="activeName">
          <el-tab-pane label="快递" name="first">
            <div class="x-common-item">
              <div class="x-common-item-left">
                <!-- <p class="x-small-title">小件快递</p> -->
                <p class="x-common-item-content" style="font-size: 20px">快速报价 大票更优</p>
                <p class="x-common-item-content" style="font-size: 20px">标准价格 重泡计费</p>
                <p class="x-common-item-content" style="font-size: 20px">一路领先让您值得信赖</p>
                <!-- <div class="x-tips">
                  <div class="x-common-item-tis">
                    <span class="x-common-item-name">• 标准快递</span>
                  </div>
                </div> -->
              </div>
              <div class="x-common-item-right">
                <img :src="require('@/assets/index/indext.png')" alt="">
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="物流" name="second">
            <div class="x-common-item">
              <div class="x-common-item-left">
                <!-- <p class="x-small-title" style="color: #004ca7">零担</p> -->
                <p class="x-common-item-content" style="font-size: 20px">大小件齐发/件数不限/重量不限</p>
                <p class="x-common-item-content" style="font-size: 20px">全程提供高质量服务</p>
                <p class="x-common-item-content" style="font-size: 20px">上至60kg免费上楼</p>
                <p class="x-common-item-content" style="font-size: 20px">更便捷、更省心、更稳定、更可靠</p>
                <!-- <div class="x-tips">
                  <div class="x-common-item-tis">
                    <span class="x-common-item-name">• 精准卡航</span>
                  </div>
                  <div class="x-common-item-tis">
                    <span class="x-common-item-name">• 精准空运</span>
                  </div>
                </div>
                <div class="x-tips">
                  <div class="x-common-item-tis">
                    <span class="x-common-item-name">• 精准汽运</span>
                  </div>
                  <div class="x-common-item-tis">
                    <span class="x-common-item-name"></span>
                  </div>
                </div>
                <p class="x-small-title">整车</p>
                <p class="x-common-item-content">十万客户信赖，成就专业整车，承载您对晨辉品质的信任，为您打造专业的整车运输方案</p>
                <div class="x-tips">
                  <div class="x-common-item-tis">
                    <span class="x-common-item-name">• 精准整车</span>
                  </div>
                  <div class="x-common-item-tis">
                    <span class="x-common-item-name">• 大票直达</span>
                  </div>
                </div> -->
              </div>
              <div class="x-common-item-right">
                <img :src="require('@/assets/index/indext.png')" alt="">
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 新闻资讯 -->
      <div class="x-news">
        <div class="x-news-title">新闻资讯</div>
        <div class="x-news-box">
          <div class="x-news-item" @click="goToNewsDetail(0)">
            <div class="x-news-item-img">
              <img :src="require('@/assets/index/indexl.png')" alt="">
            </div>
            <div class="x-news-item-title">供应链一体化趋势下，合同物流的变革</div>
            <div class="x-news-item-content">核心导读：

1)合同物流的本质是做资源配置管理。如何替代货主客户在货主与底层运力资源池之间寻找最优解，以最低的成本，提供高性价比的服务，实现管理效率的最优，才是合同物流最大的价值;

2)供应链可通过由上到下、由后向前、横向协同等多种方式实现一体化建设;

3)一体化供应链背景下，合同物流行业整合、多细分行业横向协同的规模化趋势不可逆。

1、合同物流的存在价值</div>
          </div>
          <div class="x-news-item" @click="goToNewsDetail(1)">
            <div class="x-news-item-img">
              <img :src="require('@/assets/index/indexm.png')" alt="">
            </div>
            <div class="x-news-item-title">物流的数据分析主要分析什么？</div>
            <div class="x-news-item-content">在做物流规划设计时，人们往往对设计指标感到茫然，对新员工尤其如此。有些设计人员比较急躁，一上来就急于做方案、画图，结果画来画去，就不知道自己到底要做什么了。耽误了不少时间不说，设计方案要么不知所云，要么离题万里，对用户是一个很大的伤害。

一个良好的设计习惯，往往是应该首先明确设计目标，了解清楚设计要求之后，再去动笔，就比如写文章，总应该先确定写什么主题，目的是什么，给谁看，然后才开始写提纲、反复推敲提纲、找好素材和参考资料，再动笔写，然后再反复推敲、修改润色。不然，就很难写成一篇有质量的文章。画图虽然很重要，但到底只是一种比较简单的劳动，而画什么，表现什么主题，达到什么目标才是设计的关键。</div>
          </div>
          <div class="x-news-item" @click="goToNewsDetail(2)">
            <div class="x-news-item-img">
              <img :src="require('@/assets/index/indexr.png')" alt="">
            </div>
            <div class="x-news-item-title">家电行业智能制造园区物流系统的规划与设计</div>
            <div class="x-news-item-content">为实现智能制造，家电生产企业在园区规划时要结合其生产特性，利用智能物流理念连通各个生产环节，实现满足家电制造工艺流程要求的JIT配送。本文基于家电制造业的生产流程，提出智能制造园区规划方案，重点结合行业实际情况提供了各类库区采用的物流手段，介绍了园区物流系统的设备监控、信息管理措施，并对系统规划的关键点进行了阐述。</div>
          </div>
        </div>
        <p class="seen-all" @click="goToNews">查看全部</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      banner:[{
        url:require("@/assets/banner/1.jpg")
      },{
        url:require("@/assets/banner/2.jpg")
      },{
        url:require("@/assets/banner/3.jpg")
      },{
        url:require("@/assets/banner/4.jpg")
      }],
      activeName: 'first',
      form:{
        desc:''
      },
      isShowAll: 1
    }
  },
  methods: {
    handleBlur(){
      this.isShowAll = 2
    },
    handleFocus(){
      this.isShowAll = 3
    },
    search(e){
      this.$router.push({ path: '/office/trace', query: { num:this.form.desc } });
    },
    goToNewsDetail(num){
      this.$router.push({ path: '/newsDetail', query: { num } });
    },
    goToNews(){
      this.$router.push({ path: '/news'});
    }
  }
}
</script>
<style lang="scss" scoped>
  .x-home-content{
    width: 80%;
    min-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    .x-home-tip{
      position: relative;
      height: 204px;
      margin-top: -104px;
      z-index: 999;
      display: flex;
      justify-content: space-between;
      .x-home-trace{
        flex-shrink: 0;
        flex: 1;
        background: #FFE100;
        box-sizing: border-box;
        padding: 0 30px;
        border-radius: 8px 8px 0 0;
        height: 124px;
        margin-top: 80px;
        .home-trace{
          border: 1px solid #ccc;
          border-radius: 4px;
          min-height: 40px;
          background: #fff;
          position: relative;
          .el-input{
            border: none;
          }
          /deep/.el-textarea__inner{
            height: 40px;
            border: none;
          }
        }
        p{
          text-align: left;
          font-size: 16px;
          line-height: 50px;
          margin: 0;
        }
      }
      .x-trace{
        flex-direction: row !important;
        box-sizing: border-box;
        padding: 0 40px;
        border-right: 1px solid #fff;
      }
      .x-home-dh{
        animation: heightAnimation 2s forwards;
        /deep/.el-textarea__inner{
          animation: heightInputAnimation 2s forwards;
        }
        .home-trace{
          animation: heightTraceAnimation 2s forwards;
        }
      }
      @keyframes heightAnimation {
        from {
          height: 124px;
          margin-top: 80px;
        }
        to {
          height: 204px;
          margin-top: 0px;
        }
      }
      @keyframes heightTraceAnimation {
        from {
          height: 40px;
        }
        to {
          height: 145px;
        }
      }
      @keyframes heightInputAnimation {
        from {
          height: 40px;
        }
        to {
          height: 105px;
        }
      }
      .x-home-hd{
        animation: heightAnimation1 2s forwards;
        /deep/.el-textarea__inner{
          animation: heightInputAnimation1 2s forwards;
        }
        .home-trace{
          animation: heightTraceAnimation1 2s forwards;
        }
      }
      @keyframes heightAnimation1 {
        from {
          height: 204px;
          margin-top: 0px;
        }
        to {
          height: 124px;
          margin-top: 80px;
        }
      }
      @keyframes heightTraceAnimation1 {
        from {
          height: 145px;
        }
        to {
          height: 40px;
        }
      }
      @keyframes heightInputAnimation1 {
        from {
          height: 105px;
        }
        to {
          height: 40px;
        }
      }
      .x-home-place{
        margin-top: 104px;
        height: 100px;
        flex: 1;
        flex-shrink: 0;
        background: #004ca7;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        span{
          font-size: 14px;
          line-height: 32px;
          color: #fff;
        }
      }
    }
    .x-common{
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      min-width: 1200px;
      .x-common-title{
        width: 100%;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
      }
      .x-common-item{
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px;
        .x-common-item-left{
          .x-small-title{
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            text-align: left;
            padding: 0 !important;
            margin: 0 !important;
          }
          .x-common-item-content{
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
          .x-tips{
            width: 100%;
            display: flex;
            margin-bottom: 15px;
            .x-common-item-tis{
              flex: 1;
              .x-common-item-name{
                color:#004ca7;
                text-align: left;
                display: flex;
                align-content: center;
                justify-content: flex-start;
              }
            }
          }
        }
        .x-common-item-right{
          width: 400px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 50px;
          flex-shrink: 0;
        }
      }
    }
    // 新闻资讯
    .x-news{
      width: 1100px;
      margin: 0 auto;
      .x-news-title{
        width: 100%;
        font-size: 20px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-weight: bold;
      }
      .x-news-box{
        width: 100%;
        height: 355px;
        display: flex;
        display: flex;
        justify-content: space-between;
        .x-news-item{
          width: 328px;
          height: 100%;
          border: 1px solid #ccc;
          border-radius: 8px;
          cursor: pointer;
          .x-news-item-img{
            width: 328px;
            height: 178px;
            border-radius: 8px 8px 0 0;
            img{
              width: 328px;
              height: 178px; 
              border-radius: 8px 8px 0 0;
            }
          }
          .x-news-item-title{
            height: 60px;
            width: 100%;
            font-size: 18px;
            line-height: 30px;
            color: #004ca7;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
            padding: 0 15px;
            text-align: left;
          }
          .x-news-item-content{
            width: 100%;
            height: 115px;
            font-size: 14px;
            line-height: 20px;
            overflow: hidden;
            border-radius: 0 0 8px 8px;
            box-sizing: border-box;
            padding:15px;
          }

        }
      }
      .seen-all{
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        font-weight: bold;
        color: #004ca7;
        cursor: pointer;
      }
    }
  }
  /deep/.el-tabs__nav{
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }
  /deep/.el-tabs__item{
    flex: 1;
    font-size: 16px;
    font-weight: bold;
  }
</style>