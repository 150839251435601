<template>
  <div class="x-off-footer">
    <!-- 左侧 -->
    <div class="x-off-fleft">
      <div class="x-off-fl-nav">
        <span>关于我们</span>
        <router-link to="/company">公司概述</router-link>
        <router-link to="/contactUs">联系我们</router-link>
        <router-link to="/news">新闻资讯</router-link>
      </div>
      <div class="x-off-fl-nav">
        <span>帮助中心</span>
        <router-link to="/help">帮助中心</router-link>
      </div>
    </div>
    <!-- 中间 -->
    <div class="x-off-fmid">
      <span>Copyright © 2022 河南晨辉供应链管理有限公司</span>
      <a href="https://beian.miit.gov.cn/#/Integrated/index"> 豫ICP备2022012839号</a>
    </div>
    <!-- 右侧 -->
    <div class="x-off-fright">
      <div class="x-off-fr-img">
        <el-image
          style="width: 80px; height: 80px"
          :src="require('@/assets/common/wx.png')"
          fit="contain"
        ></el-image>
        <span>关注微信公众号</span>
      </div>
      <div class="x-off-fr-content">
        <span>客户服务电话： 4007808865</span>
        <p>公司地址： 河南省郑州市新郑市郭店镇黄金大道东段丹妮物流园2排左转6、7、8号仓库</p>
        <p>邮编：450000</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  .x-off-footer{
    background: #004ca7;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .x-off-fleft{
      width: 500px;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: space-around;
      .x-off-fl-nav{
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: #fff;
        span{
          line-height: 32px;
          margin-top: 20px;
        }
        a{
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          line-height: 28px;
          opacity: 0.8;
        }
      }
    }
    .x-off-fmid{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 500px;
      span{
        color: #fff;
        font-size: 14px;
        opacity: 0.8;
        text-decoration: none;
        cursor:default;
        padding-right: 10px;
      }
      a{
        color: #fff;
        font-size: 14px;
        opacity: 0.8;
        text-decoration: none;
        cursor:pointer;
      }
    }
    .x-off-fright{
      width: 500px;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 10px;
      .x-off-fr-img{
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 10px;
        flex-shrink: 0;
        span{
          font-size: 14px;
          color: #fff;
          line-height: 20px;
        }
      }
      .x-off-fr-content{
        flex: 1;
        text-align: left;
        span{
          font-size: 18px;
          color: #AEAD2D;
          line-height: 32px;
        }
        p{
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
</style>