import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/index'; // 后台首页
import Place from '@/views/order/place'; // 在线下单
import OrderList from '@/views/order/list'; // 订单列表
import Trace from '@/views/order/trace'; // 货物追踪
import Info from '@/views/user/info'; // 个人信息
import Bank from '@/views/user/bank'; // 银行卡管理
import Address from '@/views/address/list'; // 地址列表
import Login from '@/views/login/index'; // 登录
import Register from '@/views/register/index'; // 注册
import Index from '@/views/official/index'; // 官网首页-框架
import Home from '@/views/home/index'; // 首页
import Help from '@/views/help/index'; // 帮助中心
import Company from '@/views/about/company'; // 公司概述
import News from '@/views/about/news'; // 新闻资讯
import NewsDetail from '@/views/about/newsDetail'; // 新闻资讯
import ContactUs from '@/views/about/contactUs'; // 公司概述
import HomeTrace from '@/views/home/trace'; // 货物追踪

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: Index,
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
          title: '河南晨辉供应链管理有限公司'
        }
      },
      {
        path: '/help',
        name: 'Help',
        component: Help,
        meta:{
          title: '帮助中心'
        }
      },
      {
        path: '/company',
        name: 'Company',
        component: Company,
        meta:{
          title: '公司概述'
        }
      },
      {
        path: '/news',
        name: 'News',
        component: News,
        meta:{
          title: '新闻资讯'
        }
      },      
      {
        path: '/newsDetail',
        name: 'NewsDetail',
        component: NewsDetail,
        meta:{
          title: '新闻详情'
        }
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        component: ContactUs,
        meta:{
          title: '联系我们'
        }
      },
      {
        path: '/office/trace',
        name: 'HomeTrace',
        component: HomeTrace,
        meta:{
          title: '货物追踪'
        }
      }, 
    ]
  },
  {
    path: '/layout',
    component: Layout,
    redirect: '/order/place',
    children:[
      {
        path: '/order/place',
        name: 'Place',
        component: Place,
        meta:{
          title: '在线下单'
        }
      },      
      {
        path: '/order/list',
        name: 'OrderList',
        component: OrderList,
        meta:{
          title: '全部订单'
        }
      },  
      {
        path: '/trace',
        name: 'Trace',
        component: Trace,
        meta:{
          title: '货物追踪'
        }
      }, 
      {
        path: '/info',
        name: 'Info',
        component: Info,
        meta:{
          title: '个人信息'
        }
      }, 
      {
        path: '/bank',
        name: 'Bank',
        component: Bank,
        meta:{
          title: '银行卡管理'
        }
      }, 
      {
        path: '/address',
        name: 'Address',
        component: Address,
        meta:{
          title: '地址列表'
        }
      }, 
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{
      title: '注册'
    }
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
