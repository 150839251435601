<template>
  <el-pagination
    :background="background"
    :layout="layout"
    :page-size="pageSize"
    :current-page="currentPage"
    :total="total"
    hide-on-single-page
    @current-change="currentChange"
    @size-change="sizeChange"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
    layout: {
      type: String,
      default: "total, prev, pager, next",
    },
    background: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    currentChange(currentPage) {
      this.$emit("current-change", currentPage);
    },
    sizeChange(pageSize) {
      this.$emit("size-change", pageSize);
    },
  },
};
</script>

<style lang="less" scoped>

</style>