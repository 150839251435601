import NProgress from 'nprogress';
import router from './index';
import 'nprogress/nprogress.css';

NProgress.configure({ showSpinner: false });
const whiteList = [
  '/home',
  '/help',
  '/company',
  '',
  '/',
  '/news',
  '/newsDetail',
  '/contactUs',
  '/login',
  '/register',
  '/trace',
];

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (JSON.parse(window.localStorage.getItem("userInfo"))) {
    if (to.path === '/login') {
      next({ path: '/' })
      document.title = '河南晨辉供应链管理有限公司'
      NProgress.done()
    } else {
      document.title = to.meta.title
      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      document.title = to.meta.title
      next()
    } else {
      next({ path: '/login' })
      document.title = '登录'
      NProgress.done()
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
