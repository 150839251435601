<template>
  <div class="x-off-top">
    <!-- logo -->
    <div class="x-off-img">
      <el-image
        style="width: 136px; height: 36px"
        :src="require('@/assets/logo.png')"
        fit="contain"
      ></el-image>
    </div>
    <!-- 导航 -->
    <div class="x-off-nav">
      <el-menu
        :default-active="$route.path"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#004ca7"
        text-color="#fff"
        active-text-color="#AEAD2D"
        @select="handleSelect"
        router
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/order/place">在线下单</el-menu-item>
        <el-submenu index="3">
          <template slot="title">追踪与管理</template>
          <el-menu-item index="/office/trace">货物追踪</el-menu-item>
          <el-menu-item index="/order/list">我的订单</el-menu-item>
        </el-submenu>
        <el-menu-item index="/help">帮助中心</el-menu-item>
        <el-submenu index="5">
          <template slot="title">关于我们</template>
          <el-menu-item index="/company">公司概述</el-menu-item>
          <el-menu-item index="/news">新闻资讯</el-menu-item>
          <el-menu-item index="/contactUs">联系我们</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <!-- 右侧区域 -->
    <div class="x-off-info">
      <div v-if="isShowInfo"> 
        <img class="top-bar__img"
            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" @click='gotoUser'>
        <el-dropdown class="x-off-user">
          <span class="el-dropdown-link" @click='gotoUser'>
            {{ userInfo.nickname }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleLogOut"
                              >退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="nav" v-else>
        <router-link to="/login">注册</router-link> |
        <router-link to="/login">登录</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { validatenull } from "@/common/common.js"
export default {
  data() {
    return {
      activeIndex: "1",
      userInfo: {},
      isShowInfo: false
    };
  },
  created () { 
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
    this.isShowInfo = !validatenull(JSON.parse(window.localStorage.getItem("userInfo")))
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleLogOut(){
      window.localStorage.removeItem("userInfo");
      this.$router.push('/login')
    },
    gotoUser(){
      this.$router.push('/info')
    }
  },
};
</script>
<style lang="scss" scoped>
.x-off-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #004ca7;
  height: 100%;
  .x-off-img {
    width: 136px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  .x-off-nav{
    flex: 1;
    .el-menu-demo{
      height:50px !important;
    }
    /deep/.el-menu--horizontal>.el-menu-item,.el-submenu{
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 16px;
    }
    /deep/.el-menu--horizontal>.el-submenu .el-submenu__title{
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 16px;
    }
    /deep/.el-menu--horizontal>.el-menu-item.is-active{
      border-bottom: 2px solid #AEAD2D;
      color: #fff;
    }
    /deep/.el-menu--horizontal>.el-menu-item a{
      text-decoration: none;
    }
    /deep/.el-dropdown-menu__item, .el-menu-item{
      padding: 0 40px !important;
    }
    /deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
      border-bottom: 2px solid #AEAD2D;
      color: #fff;
    }
  }
  .x-off-info {
    width: 250px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
    .nav {
      padding: 30px;
      color: #AEAD2D;
      font-size: 14px;
      a {
        font-weight: bold;
        color: #AEAD2D;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .top-bar__img {
      margin: 0 5px;
      padding: 2px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      box-sizing: border-box;
      border: 1px solid #eee;
      vertical-align: middle;
      cursor: pointer;
    }
    .x-off-user{
      color: #fff;
    }
  }
}
/deep/.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{
  color: #fff !important;
}
</style>
