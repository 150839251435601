<template>
  <div class="">
    <el-card class="box-card">
      <div class="" style="margin-bottom: 15px;text-align:left">
        <el-button type="primary" icon="el-icon-bank-card" size="small" @click="formVisible = true">添加银行卡</el-button>
      </div>
      <el-table :data="dataList" border size="medium" >
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="bank_name" label="银行名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="tel_no" label="手机号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="card_id" label="银行卡号" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="stock" label="身份证号" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="action" label="操作" width="140">
          <template slot-scope="scope">
            <div>
              <!-- <span style="color: #409eff; cursor: pointer" @click="handleEdit(scope.row)"
                >修改</span
              ><el-divider direction="vertical"></el-divider> -->
              <span style="color: #f40000; cursor: pointer" @click="handleDel(scope.row)"
                >删除</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>

    <!-- 表单弹窗 -->
    <el-dialog
      title="添加银行卡"
      append-to-body
      :visible.sync="formVisible"
      width="600px"
      :before-close="closeDialog"
    >
      <BankForm
        ref="formModal"
        :record="record"
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import BankForm from "./bankForm";
import { bankIndex, bankDel } from '@/api'
export default {
  components: {
    BankForm,
  },
  data() {
    return {
      formVisible: false,
      record: {},
      searchForm: {
        materielName: "",
        materielNameResult: "",
      },
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
    };
  },
  created() {
    this.getBankIndex()
  },
  methods: {
    // 获取列表
    getBankIndex(){
      bankIndex({
        u_id:JSON.parse(window.localStorage.getItem("userInfo")).id,
        page: this.current,
        limit: this.size
      }).then(res => {
        if(res.code == 1){
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // 关闭弹框
    closeDialog() {
      this.record = {};
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false;
      this.getBankIndex();  //刷新列表
    },
    //视频监控站点新增
    handleAdd() {
      this.$refs.editModal.title = "添加银行卡";
      this.$refs.editModal.createOrEdit({ id: "" });
    },
    //视频监控站点修改
    handleEdit(record) {
      this.$refs.editModal.title = "编辑";
      this.$refs.editModal.createOrEdit(record);
    },
    //视频监控站点删除
    handleDel(record) {
      this.$confirm("确认删除?", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          bankDel({ 
            u_id:JSON.parse(window.localStorage.getItem("userInfo")).id,
            bank_id: record.id 
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg);
              this.getBankIndex();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getBankIndex();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
