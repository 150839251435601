<!--防洪体系-->
<template>
  <el-card class="box-card">
    <div class="search-form" style="text-align: left">
      <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="70px" label-position="left"
        class="demo-form-inline">
        <el-form-item label="创建时间" prop="materielNameResult">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="单号" label-width="40px">
          <el-input clearable size="small" v-model="searchForm.no" placeholder="请输入单号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
          <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="dataList" border size="medium">
      <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
      <el-table-column prop="reservation_num" label="单号" show-overflow-tooltip>
        <template slot-scope="scope">
          <span @click="search(scope.row.reservation_num)" style="cursor: pointer;">{{ scope.row.reservation_num }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="od_num" label="关联单号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sendAddress" label="寄件地址" width="320" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.sendProvince }}{{ scope.row.sendAddress }} {{ scope.row.sender }}
        </template>
      </el-table-column>
      <el-table-column prop="collectProvince" label="收件地址" width="320" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.collectProvince }}{{ scope.row.collectAddress }} {{ scope.row.collector }}
        </template>
      </el-table-column>
      <el-table-column prop="freight" label="运费" show-overflow-tooltip></el-table-column>
      <el-table-column prop="money" label="代收货款" show-overflow-tooltip></el-table-column>
      <el-table-column prop="action" label="操作" width="140">
        <template slot-scope="scope">
          <div>
            <span style="color: #409eff; cursor: pointer" v-if="scope.row.pay_zt == 3">支付失败</span>
            <span style="color: #409eff; cursor: pointer" v-if="scope.row.pay_zt == 2">回调中</span>
            <span style="color: #409eff; cursor: pointer" v-if="scope.row.pay_zt == 1">已支付</span>
            <span style="color: #409eff; cursor: pointer" v-if="scope.row.freight > 0 && scope.row.pay_zt == 0" @click="handlePay(scope.row)">待支付</span>
            <span style="color: #409eff; cursor: pointer" v-if="scope.row.freight <= 0 && scope.row.pay_zt == 0">未设置运费</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagization-box">
      <CustomPagization :background="true" :currentPage="current" :total="total" :pageSize="size"
        @current-change="currentChange" />
    </div>
    <!-- 表单弹窗 -->
    <el-dialog
      title="支付方式"
      append-to-body
      :visible.sync="formVisible"
      width="500px"
      :before-close="closeDialog"
    >
      <PayType
        ref="formModal"
        :record="record"
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </el-card>
</template>

<script>
import { orderIndex } from '@/api'
import PayType from "./components/payType";
export default {
  components: {
    PayType,
  },
  data() {
    return {
      formVisible: false,
      record: {},
      searchForm: {
        begin_time: "",
        end_time: "",
        no: ""
      },
      value1:[],
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
    };
  },
  created() {
    this.getOrderIndex();
  },
  methods: {
    // 关闭弹框
    closeDialog() {
      this.record = {};
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false;
      this.getOrderIndex();  //刷新列表
    },
    // 获取列表
    getOrderIndex(){
      orderIndex({
        u_id:JSON.parse(window.localStorage.getItem("userInfo")).id,
        page: this.current,
        limit: this.size,
        begin_time: this.value1.length > 0 ? this.value1[0] : '',
        end_time: this.value1.length > 0 ? this.value1[1] : '',
        no: this.searchForm.no
      }).then(res => {
        if(res.code == 1){
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    //视频监控站点修改
    handlePay(record) {
      this.formVisible = true
      this.record = record
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getOrderIndex();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getOrderIndex();
    },
    // 跳往轨迹
    search(num){
      this.$router.push({ path: '/trace', query: { num:num } });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
