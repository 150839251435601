<template>
  <div class="">注册</div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  
</style>