<template>
  <div>
    <div class="pay">
      <div class="pay-item" @click="selectBacks(1)">
        <img class="pay-img" :src="require('@/assets/bank/yin.png')">
        <span class="pay-name">银联支付</span>
      </div> 
      <div class="pay-item" @click="selectBacks(2)">
        <img class="pay-img" :src="require('@/assets/bank/pay.png')">
        <span class="pay-name">网银支付</span>
      </div>
      <div class="pay-item" @click="selectBacks(3)">
        <img class="pay-img" :src="require('@/assets/bank/zhong.png')" style="margin-left:20px;width: 47px;height: 47px">
        <span class="pay-name">中信全付通</span>
      </div>
      <div class="pay-item" @click="selectBacks(4)">
        <img class="pay-img" :src="require('@/assets/bank/b2b.png')" style="height: 46px">
        <span class="pay-name">中信B2B</span>
      </div>
    </div>
    <!-- 表单弹窗 -->
    <el-dialog
      title="银行卡"
      append-to-body
      :visible.sync="formVisible"
      width="600px"
      :before-close="closeDialog"
    >
      <SelectBack
        ref="formModal"
        :type="type"
        :records='records'
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import SelectBack from "./selectBack";
import { publicPay, zhongxinPay, ecepcpay } from '@/api'
export default {
  components: {
    SelectBack,
  },
  props: {
    record: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formVisible: false,
      type:1,
      records: {}
    }
  },
  mounted(){
    this.records = this.record
  },
  methods: {
    // 关闭弹框
    closeDialog() {
      this.type = 1;
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false;
      // this.getOrderIndex();  //刷新列表
    },
    selectBacks(num){
      if(num == 1){
        publicPay({order_id:this.record.id}).then(res => {
          if(res.code == 1){
            window.open(res.data, '_blank');
          }else{
            this.$message.error(res.msg);
          }
        })
      }else if(num == 2){
        this.type = num
        this.formVisible = true
      }else if(num == 3){
        zhongxinPay({order_id:this.record.id}).then(res => {
          if(res.code == 1){
            window.open(res.data.CODEURL, '_blank');
          }else{
            this.$message.error(res.msg);
          }
        })
      }else if(num == 4){
        ecepcpay({order_id:this.record.id}).then(res => {
          if(res.code == 1){
            window.open(res.data.REDIRECTURL, '_blank');
          }else{
            this.$message.error(res.msg);
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .pay{
    width: 100%;
    box-sizing: border-box;
    padding:20px 40px;
    .pay-item{
      width: 100%;
      height: 70px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ccc;
      border-radius: 4px;
      cursor: pointer;
      .pay-img{
        height: 50px;
        width: 50px;
        margin-right: 50px;
      }
      .pay-name{
        font-size: 20px;
      }
    }
    .pay-item:hover{
      background: #409EFF;
      .pay-name{
        color: #fff;
      }
    }
  }
</style>