<template>
  <div class="tips-box">
    <!-- <div class="tips-item tips-wxs">
      <el-image
        style="width: 32px; height: 25px"
        :src="require('@/assets/common/wxi.gif')"
        fit="contain"
      ></el-image>
      <span>关注微信</span>
      <div class="tips-wx">
        <el-image
          style="width: 120px; height: 120px"
          :src="require('@/assets/common/wx.png')"
          fit="contain"
        ></el-image>
      </div>
    </div> -->
    <div class="tips-item" @click="scrollToTop">
      <el-image
        style="width: 32px; height: 25px"
        :src="require('@/assets/common/top.gif')"
        fit="contain"
      ></el-image>
      <span>回到顶部</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  }
}
</script>
<style lang="scss" scoped>
  .tips-box{
    .tips-item{
      width: 86px;
      height: 86px;
      background: #004ca7;
      border-radius: 8px 0 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 1px;
      position: relative;
      cursor: pointer;
      span{
        color: #fff;
        font-size: 14px;
        line-height: 28px;
      }
      .tips-wx{
        display: none;
        width: 150px;
        height: 150px;
        background: #fff;
        position: absolute;
        right: 100px;
        top: -65px;
        box-sizing: border-box;
        padding: 15px;
        border-radius: 8px;
      }
    }
    .tips-wxs:hover{
      .tips-wx{
        display: block;
      }
    }
  }
</style>