<template>
  <div class="">
    <img
      :src="require('@/assets/about/combanner.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%"
    />
    <!-- 面包屑 -->
    <div class="x-help-bread">
      首页 <i class="el-icon-arrow-right x-help-icon"></i> 
      公司概述
    </div>
    <!-- 公司简介 -->
    <div class="comp">
      <div class="comp-title">公司简介</div>
      <div class="comp-box">
        <img
          :src="require('@/assets/about/comsmall.png')"
          alt="Carousel Image"
          style="display: inline-block; width: 100%"
        />
        <div class="comp-small-title">晨辉快递简介</div>
        <div class="comp-content">
          河南晨辉供应链管理有限公司成立于2020年07月23日，注册地位于河南省郑州市新郑市郭店镇黄金大道东段丹妮物流园2排左转6、7、8号仓库，法定代表人为王小彩。经营范围包括供应链管理服务；仓储服务；餐饮服务；货物装卸服务；房屋租赁；道路普通货物运输；货运站经营；销售：汽车配件、日用百货、化工产品、家用电器、预包装食品；汽车租赁。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.x-help-bread {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background: #004ca7;
  box-sizing: border-box;
  padding-left: 10%;
  color: #fff;
  font-size: 14px;
  .x-help-icon {
    padding: 0 5px;
    margin-top: 3px;
  }
}
.comp {
  width: 80%;
  margin: 0 auto;
  min-width: 1200px;
  .comp-title {
    width: 100%;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
  }
  .comp-box {
    width: 100%;
    box-sizing: border-box;
    padding: 40px;
    background: #fff;
    margin-bottom: 40px;
    border-radius: 8px;
    .comp-small-title {
      margin-top: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #004ca7;
      text-align: left;
      line-height: 32px;
    }
    .comp-content {
      width: 100%;
      font-size: 14px;
      text-align: left;
      line-height: 20px;
    }
  }
}
</style>