<template>
  <div class="agreement">
    <p>
      欢迎使用Adapay平台协议支付服务，本服务由上海汇付数据服务有限公司（本公司）向您提供。在接受本服务协议之前，请您充分、阅读理解本协议的全部内容。您通过平台页面完成点击确认操作即表示双方已达成本协议。您承诺具有法律规定的完全民事权利能力和行为能力，且为能够独立承担民事责任，您已充分阅读井理解接受本协议的全部约定内容，若您不符合上述要求或不同意本协议内容，或者无法;准确理解相关
      条款含义，应当立即停止后续操作，并与本公司及时取得联系，对于已经实际开展的双方合作，本公司将子以暂停，已经签署的相关产品合作协议视为中止。本协议的签署时间以本公司系统显示为准。
    </p>
    <p>
      您同意，本公司有权对本协议内容进行修改，并在本界面以公告的方式公布，无需另行通知;若您在本公司公告协议内容变更后继续与本公司展开合作的，表示您已充分问读、理解并接受修改后的协议内容；若您不同意修改后的协议内容，或者无法准确理解相关条款含义，应当立即停止后续操作，并与本公司及时取得联系，对于已经实际开展的双方合作，本公司将予以暂停，已经签署的相关产品合作协议视为中止。
    </p>
    <p>
      本公司已采取合理方式提请您注意本协议的全部条款，并对其内容进行了充分说明。您自愿按照本协议的约定行使权利和履行义务。
    </p>
    <p>一、权利义务</p>
    <p>
      1.您保证您是使用本服务的银行卡持有人，可合法、有效使用该银行卡且未侵犯任何第三方合法权益，否则，因此造成本公司、持卡人损失的，您应负责赔偿井承担法律责任。
    </p>
    <p>
      2.您应确保开通本服务所提供的手机号码为您本人所有，为此，您理解并同意本公司通过第三方渠道对您所提供手机号码的真实性、有效性进行核实。
    </p>
    <p>
      3.为了防范交易风险及保障您的资金安全，您理解并同意本公司使用您提供的信息，如：姓名、身份证件号码、手机号码、银行卡信息（卡号、有效期、卡验证码等）及预留手机号，与发卡行进行交叉验证。
    </p>
    <p>
      4.出于提供本服务的目的，您授权本公司收集您的姓名、身份证件号码、手机号码、银行卡信息（卡号、有效期、卡验证码等）
      等个人信息；本公司重视对个人信息处理与保护，将遵循合法、正当、必要的原则收集您的个人信息并严格保护您个人信息。
    </p>
    <p>
      5.您应妥善保管银行卡、卡号、密码、发卡行预留的手机号码、数字证书、手机动态口令、校验码等与银行卡有关的信息和设备。如您遗失或泄露前述信息和/或设备的，您应及时通知发卡行及/或本公司。无论您是否已通知发卡行及/或本公司，因您自身原因所致损失由您自行承担。
    </p>
    <p>6.您在使用本服务时，应当认真确认交易信息，包括且不限于商品名称、数量、金额等。您知悉并同意：您发出的指令不可撒销，本公司根据您的指令从银行卡中划扣资金给收款人，您不应以非本人意愿交易或其他原因要求本公司承担任何责任。</p>
    <p>7.您在对使用本服务过程中发出指令的真实性及有效性负责，本公司依照您的指令进行操作的风险须由您承担。交易数据以本公司记载为准。</p>
    <p> 8.您承诺您使用本服务时所提供的个人信息真实、合法、有效、完整、准确。本公司将按照您设置的相关信息为您提供相应服务。若您设置的信息有误，您需自行承担相应责任及风险。</p>
    <p>9.您同意本公司为满足反洗钱及监管的要求，留存您的交易记录。在您使用本服务期间及本服务终止后，您理解并同意本公司在相关法律法规或监管要求的期限内保留您在使用本服务过程中提供或新产生的相关信息。</p>
    <p>10.出现下列情況之一的，本公司有权立即终止您使用本服务而免于承担任何责任： （1）
    将本服务用于非法目的； （2） 违反本协议的约定; （3） 本公司认为向您提供本服务存在交易风险的；
    （4）您的银行卡 无效、有效期届满或注销。</p>
    <p>二、适用法律及争议解决</p>
    <p>1.本协议的解释、适用、争议解决等一切事宜，均适用中华人民共和国法律(香港特别行政区、澳门特别行政区和台湾地区的法律除外），冲突法规则除外。</p>
    <p> 2.因本协议产生任何纠纷时，各方应友好协商，协商不成的，各方应将争议提交本公司佳所地有管辖权的人民法院诉讼解决，且诉讼费用（包括律师费、公证费等）由败诉方承担。</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>

  .agreement{
    font-size: 14px;
    line-height: 1.5;
    p{
      padding: 0;
      margin: 0;
      margin-bottom: 10px;
    }
  }
</style>
