<template>
  <div class="">
    <img
      :src="require('@/assets/contactus/conbanner.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%"
    />
    <!-- 面包屑 -->
    <div class="x-help-bread">
      首页 <i class="el-icon-arrow-right x-help-icon"></i> 
      联系晨辉
    </div>
    <!-- 联系晨辉 -->
    <div class="cont">
      <div class="cont-title">联系我们</div>
      <div class="cont-box">
        <div class="cont-item">
          <!-- <div class="cont-item-img">
            <img :src="require('@/assets/contactus/phone.gif')" alt="">
          </div> -->
          <p class="cont-item-title">客户服务热线</p>
          <p class="cont-item-phone">4007808865</p>
        </div>
        <div class="cont-item">
          <!-- <div class="cont-item-img">
            <img :src="require('@/assets/contactus/add.gif')" alt="">
          </div> -->
          <p class="cont-item-title">总部地址</p>
          <p class="cont-item-content">河南省郑州市新郑市郭店镇黄金大道东段丹妮物流园2排左转6、7、8号仓库邮编450000</p>
        </div>
      </div>
    </div>
    <!-- <img
      :src="require('@/assets/contactus/map.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%;margin-top:20px"
    /> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  .x-help-bread {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: #004ca7;
    box-sizing: border-box;
    padding-left: 10%;
    color: #fff;
    font-size: 14px;
    .x-help-icon {
      padding: 0 5px;
      margin-top: 3px;
    }
  }
  .cont{
    width: 80%;
    margin: 20px auto;
    min-width: 1200px;
    .cont-title{
      width: 100%;
      font-size: 20px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-weight: bold;   
    }
    .cont-box{
      display: flex;
      justify-content: center;
      margin: 10px 0 20px;
      .cont-item{
        width: 321px;
        height: 345px;
        margin: 0 15px;
        box-sizing: border-box;
        padding: 60px 23px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: #fff;
        .cont-item-img{
          width: 100px;
          height: 100px;
          margin: 0 auto 10px;
          background: #004ca7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 60px;
            height: 60px;
          }
        }
        .cont-item-title{
          font-size: 24px;
        }
        .cont-item-phone{
          font-size: 24px;
          color: #004ca7;
          margin-top: 30px;
        }
        .cont-item-content{
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
</style>