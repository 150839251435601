<template>
  <div class="">
    <img :src="require('@/assets/help/helpbanner.png')" alt="Carousel Image" style="display: inline-block;width: 100%;">
    <!-- 面包屑 -->
    <div class="x-help-bread">首页 <i class="el-icon-arrow-right x-help-icon"></i> 帮助中心</div>
    <!-- 热门问题 -->
    <div class="help">
      <div class="help-title">热门问题</div>
      <div class="help-box">
        <div class="help-item">
          <div class="help-name">订单查不到如何处理</div>
          <div class="help-content">在网站或者微信公众号中可查看订单号，或者联系客服进行处理</div>
        </div>
        <div class="help-item">
          <div class="help-name">如何查询运单</div>
          <div class="help-content">进入网站首页，点击“货物追踪”，填写运单号进行查询</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  .x-help-bread{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: #004ca7;
    box-sizing: border-box;
    padding-left: 10%;
    color: #fff;
    font-size: 14px;
    .x-help-icon{
      padding: 0 5px;
      margin-top: 3px;
    }
  }
  .help{
    width: 80%;
    margin: 0 auto;
    min-width: 1200px;
    .help-title{
      width: 100%;
      font-size: 20px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-weight: bold;  
    }
    .help-box{
      width: 100%;
      height: 300px;
      margin-bottom: 60px;
      display: flex;
      justify-content: space-between;
      .help-item{
        flex: 1;
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 10px 20px;
        border-right: 1px solid #ccc;
        .help-name{
          font-size: 18px;
          line-height: 32px;
          text-align: left;
          margin-bottom: 10px;
        }
        .help-content{
          width: 100%;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
        }
      }
      .help-item:last-child{
        border: none;
      }
    }
  }
</style>